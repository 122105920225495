import React from 'react';
import ReactParticles from 'react-particles-js';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { media } from '../../styles/constants';

export const ParticlesContainer = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  min-height: calc(50vh - 3.75rem);
  align-items: center;

  @media (min-width: ${media.desktop}px) {
    max-height: 480px;
  }

  @media (min-width: ${media.bigDesktop}px) {
    max-height: 480px;
  }

  background: radial-gradient(
    circle,
    rgb(101, 141, 165) 0%,
    rgb(27, 60, 80) 100%
  );

  .particles {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const Particles = ({ Container = ParticlesContainer, children }) => (
  <Container>
    <ReactParticles
      className="particles"
      params={{
        particles: {
          number: {
            value: 200,
            density: {
              enable: true,
              value_area: 1400
            }
          },
          size: {
            value: 0.5
          },
          move: {
            speed: 0.75
          }
        },
        interactivity: {
          events: {
            onclick: {
              enable: true,
              mode: 'push'
            }
          }
        }
      }}
    />
    {children}
  </Container>
);

Particles.propTypes = {
  Container: PropTypes.object,
  children: PropTypes.node.isRequired
};

export default Particles;
